import React, {Component} from 'react';
import Menu from "../components/menu";
import MenuItem from "../components/menu/MenuItem";
import RouterStore from "../stores/RouterStore";
import {inject} from "mobx-react";
import WPService from "../services/WPService";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactDOM from "react-dom";
import * as moment from "moment";
import Helpers from "../utils/Helpers";
import ReactTooltip from "react-tooltip";
import { Roller } from "react-awesome-spinners";

interface IArchiveProps {
    match?: any;
    routing?: RouterStore,
    wpService?: WPService,
    categories?: any,
}

interface IArchiveState {
    year: number;
    posts: Array<any>,
    page: number,
    pageSize: number,
    postHasMore: boolean,
    scrollableTarget?: any,
    postAfter: string,
    postBefore: string,
}

@inject('categories')
@inject('wpService')
@inject('routing')
export default class Archive extends Component<IArchiveProps, IArchiveState> {
    private limitedCategory = ['uudised', 'info', 'varia'];
    private limitedCategoryIds = '';

    constructor(props) {
        super(props);
        const year = parseInt(this.props.match.params.year);
        this.state = {
            year,
            postAfter: `${year - 1}-12-31T23:59:59`,
            postBefore: `${year + 1}-01-01T00:00:00`,
            page: 1,
            posts: null,
            pageSize : 100,
            postHasMore: false
        }
        this.onSelectYear = this.onSelectYear.bind(this);
        this.selectPost = this.selectPost.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.limitedCategoryIds = this.limitedCategory.map(slug => {
            const category = this.props.categories.filter(category => category.slug === slug).first;
            return category ? String(category.id) : null;
        }).filter(slug => slug).join(',');
        this.loadPosts();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const year = parseInt(nextProps.match.params.year);
        return {
            year,
            posts: year === prevState.year ? prevState.posts : [],
            page: year === prevState.year ? prevState.page : 1,
        }
    }

    componentDidUpdate(prevProps: Readonly<IArchiveProps>, prevState: Readonly<IArchiveState>, snapshot?: any) {
        if(this.state.year !== prevState.year) {
            const postAfter = `${this.state.year - 1}-12-31T23:59:59`;
            const postBefore = `${this.state.year + 1}-01-01T00:00:00`
            this.loadPosts(1, null, postAfter, postBefore);
            this.setState({posts: null});
        }
    }

    componentDidMount() {
        this.setState({scrollableTarget: ReactDOM.findDOMNode(this).parentElement});
    }

    render() {
        return (
            <div>
                <Menu className={'item-inline-block'} center={true}>
                    {[2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010].map(year => (
                        <MenuItem key={year} label={String(year)} border={true} color={this.state.year === year ? 'green' : 'blue'} onClick={this.onSelectYear.bind(this, year)}/>)
                    )}
                </Menu>
                <div style={{height: "10px"}}/>
                {this.state.posts === null && <div className={"loading center"}>
                    <Roller/>
                </div>}
                {this.state.posts !== null && <div className="archive-post-list">
                    <InfiniteScroll
                        dataLength={this.state.posts.length} //This is important field to render the next data
                        next={this.fetchData}
                        hasMore={this.state.postHasMore}
                        loader={<div className={"center"}><Roller/></div>}
                        scrollableTarget={this.state.scrollableTarget}
                    >
                        {this.state.posts.map((post, postIndex) => (
                            <div key={postIndex} className="row">
                                <div className="column">
                                    {moment(post.date).format('DD.MM.YYYY')}
                                </div>
                                <div className="column">
                                    <a data-tip data-for={`post-${post.id}`} href={post.link} onClick={this.selectPost}
                                       dangerouslySetInnerHTML={{__html: post.title.rendered}}/>
                                    {this.renderToolTip(post)}
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div>}
            </div>
        )
    }

    renderToolTip(post) {
        const imgSrc = Helpers.getPostThumbnailImgSrc(post);
        if(imgSrc) {
            return (
                <ReactTooltip id={`post-${post.id}`} type='light'>
                    <img src={imgSrc} alt=""/>
                </ReactTooltip>
            );
        }
        return '';
    }

    fetchData() {
        this.loadPosts(this.state.page + 1, null, null, true);
    }

    loadPosts(page = null, pageSize=null, postAfter=null, postBefore=null, append=false) {
        if(!page) {
            page = this.state.page;
        }
        if(!pageSize) {
            pageSize = this.state.pageSize
        }
        if(!postAfter) {
            postAfter = this.state.postAfter
        }
        if(!postBefore) {
            postBefore = this.state.postBefore
        }
        const categoryIds = this.limitedCategoryIds.split(',').map(id => parseInt(id)).filter(id => Number.isInteger(id));
        this.props.wpService.getPosts(page, pageSize, categoryIds, null, postBefore, postAfter).then(response => {
            const posts = append ? [...this.state.posts, ...response.data] : response.data;
            this.setState({
                posts,
                page,
                pageSize,
                postBefore,
                postAfter,
                postHasMore: posts.length >= pageSize * page
            })
        })
    }

    onSelectYear(year) {
        this.props.routing.push(`/arhiiv/${year}`);
    }

    selectPost(e) {
        e.preventDefault();
        e.stopPropagation();
        if(e.currentTarget.href) {
            const url = new URL(e.currentTarget.href);
            this.props.routing.push(url.pathname);
        }
    }
}
