import {Component} from "react";
import * as React from "react";
import Icon from "../icon";
import MenuRight from "./MenuRight";
const VeebilahendusedLogo = require("../../assets/logos/veebilahendused-logo.png");

export default class SidebarRight extends Component {
    render() {
        return (
            <div className="sidebar-right">
                <div className="sticky-spacer"/>
                <div className="sticky-content">
                    <MenuRight/>
                    <div className=" background-white-transparent social-block">
                        <div>
                            <a href="https://www.facebook.com/Tartumaa-Kurtide-%C3%9Ching-399994233383622" target="_blank"><Icon icon={'facebook'}/></a>
                            <a href="https://www.youtube.com/user/TARKYvideo" target="_blank"><Icon icon={'youtube'}/></a>
                            {/*<a href=""><Icon icon={'instagram'} /></a>*/}
                        </div>
                    </div>
                    <div className="copyright-block">
                        <div>
                            © 2021-2025 Kõik õigused kaitstud. Veebilahendused
                            <a href="https://www.veebilahendused.ee" target="_blank"><img src={VeebilahendusedLogo} alt={"Veebilahendused"}/></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
